import React from 'react';
import {ScreenWrapper} from "./ScreenWrapper";

function App() {
  return (
    <ScreenWrapper />
  );
}

export default App;
