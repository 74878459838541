import React from "react";

export const VkIcon = (props) => {
    return (<svg {...props}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.2 42.2">
        <path d="M21.1,0C9.4,0,0,9.4,0,21.1s9.4,21.1,21.1,21.1s21.1-9.4,21.1-21.1S32.7,0,21.1,0z M31.8,23.4c1,1,2,1.9,2.9,2.9
            c0.4,0.5,0.8,1,1,1.5c0.4,0.8,0,1.6-0.7,1.7l-4.3,0c-1.1,0.1-2-0.4-2.8-1.1c-0.6-0.6-1.2-1.3-1.7-1.9c-0.2-0.3-0.5-0.5-0.8-0.7
            c-0.6-0.4-1.1-0.3-1.4,0.3c-0.3,0.6-0.4,1.3-0.5,2c0,1-0.4,1.3-1.4,1.3C20,29.5,18,29.2,16,28.1c-1.7-1-3-2.3-4.2-3.9
            c-2.2-3-3.9-6.3-5.5-9.8c-0.3-0.8-0.1-1.2,0.8-1.2c1.4,0,2.8,0,4.2,0c0.6,0,1,0.3,1.2,0.9c0.8,1.9,1.7,3.7,2.9,5.3
            c0.3,0.4,0.6,0.9,1.1,1.2c0.5,0.3,0.9,0.2,1.1-0.3c0.2-0.4,0.2-0.7,0.2-1.1c0.1-1.3,0.1-2.6-0.1-3.9c-0.1-0.8-0.6-1.3-1.4-1.5
            c-0.4-0.1-0.4-0.2-0.2-0.5c0.3-0.4,0.7-0.7,1.3-0.7l4.9,0c0.8,0.2,0.9,0.5,1,1.3l0,5.4c0,0.3,0.2,1.2,0.7,1.4
            c0.4,0.1,0.7-0.2,1-0.5c1.2-1.2,2-2.7,2.7-4.2c0.3-0.7,0.6-1.4,0.9-2.1c0.2-0.5,0.5-0.8,1.1-0.8l4.7,0c0.1,0,0.3,0,0.4,0
            c0.8,0.1,1,0.5,0.8,1.2c-0.4,1.2-1.1,2.2-1.9,3.2c-0.8,1.1-1.6,2.1-2.4,3.2C30.8,22,30.9,22.5,31.8,23.4z"/>
    </svg>)

}
